<template>
  <vs-popup :title="`${$t('dashboard.amendments.popup.heading1')} > ${organization.name}`" :active.sync="showPopup">
    <div class="popup">

      <!-- HEADER -->
      <vs-row vs-w="12" class="mb-4">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div v-if="request.reviewed">
            <vs-chip v-if="request.reviewed===true" color="success"> Genehmigt am {{ request.reviewed_at | moment("DD.MM.YYYY") }} </vs-chip>
          </div>
          <div v-else-if="request.reviewed===false">
            <vs-chip v-if="request.reviewed===false" color="danger">
              Abglehnt am {{ request.reviewed_at | moment("DD.MM.YYYY") }}
            </vs-chip>
          </div>
          <div v-else>
            <vs-chip v-if="request.reviewed===null" color="primary"> Erstellt am {{ request.created_at | moment("DD.MM.YYYY") }}</vs-chip>
          </div>
        </vs-col>
      </vs-row>

      <!-- BODY -->
      <vs-row class="ps-3 pe-3">

        <vs-row>
          <vs-col vs-w="6"><strong>Original</strong></vs-col>
          <vs-col vs-w="6"><strong>Änderung</strong></vs-col>
        </vs-row>

        <vs-row class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2" 
              :label-placeholder="$t('dashboard.amendments.popup.first-name')" 
              v-model="person.first_name" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2" 
              :label-placeholder="$t('dashboard.amendments.popup.first-name')" 
              :value="getChangedField('first_name')" readonly />
          </vs-col>
        </vs-row>

        <vs-row class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.last-name')" 
              v-model="person.last_name" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2" 
              :label-placeholder="$t('dashboard.amendments.popup.last-name')" 
              :value="getChangedField('last_name')" readonly />
          </vs-col>
        </vs-row>

        <vs-row class="mt-3">
          <vs-col vs-w="6">
            <vs-input type="date" class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.date-birth')" 
              v-model="person.date_of_birth" />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input type="date" class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.date-birth')" 
              :value="getChangedField('date_of_birth')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.function')" 
              v-model="person.role" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.function')" 
              :value="getChangedField('role')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.driver-license')" 
              v-model="person.driver_license_class" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.driver-license')"
              :value="getChangedField('driver_license_class')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.department')" 
              v-model="person.department" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('dashboard.amendments.popup.department')"
              :value="getChangedField('department')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra1')" 
              v-model="person.extra_1" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra1')"
              :value="getChangedField('extra_1')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra2')" 
              v-model="person.extra_2" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra2')" 
              :value="getChangedField('extra_2')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra3')" 
              v-model="person.extra_3" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra3')" 
              :value="getChangedField('extra_3')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra4')" 
              v-model="person.extra_4" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra4')" 
              :value="getChangedField('extra_4')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra5')" 
              v-model="person.extra_5" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra5')" 
              :value="getChangedField('extra_5')" readonly />
          </vs-col>
        </vs-row>

        <vs-row v-if="!isPreconfLayout" class="mt-3">
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra6')" 
              v-model="person.extra_5" readonly />
          </vs-col>
          <vs-col vs-w="6">
            <vs-input class="simple-input w-100 pe-2"
              :label-placeholder="$t('Extra6')" 
              :value="getChangedField('extra_6')" readonly />
          </vs-col>
        </vs-row>



        <vs-row class="mt-3">
          <vs-col vs-w="6">
            <img v-if="profileImage" :src="'data:image/png;base64, '+ personImg64" />
          </vs-col>
          <vs-col vs-w="6">
            <img v-if="profileImage" :src="'data:image/png;base64, '+ requestImg64" />
          </vs-col>
        </vs-row>





      </vs-row>



      <!-- ACTION BUTTONS -->
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <vs-button v-if="request.reviewed===null" class="pop-btn" color="danger" type="filled" icon="cancel" @click="reviewAmendment('false')">{{$t('decline')}}</vs-button>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="6">
          <vs-button v-if="request.reviewed===null" class="pop-btn" color="primary" type="filled" icon="done" @click="reviewAmendment('true')">{{$t('Annehmen')}}</vs-button>
          <vs-button class="pop-btn" color="primary" type="filled" icon="close" @click="showPopup=!showPopup">{{$t("cancel")}}</vs-button>
        </vs-col>
      </vs-row>


      <!-- DEBUG MODE -->
      <vs-row v-if="isDebugMode">
        <h1>organization</h1>
        {{organization}}
        <h1>Person</h1>
        {{person}}
        <h1>REQUEST</h1>
        {{request}}
      </vs-row>    
    
    </div>   
  </vs-popup>
</template>

<script>
  import Vue from 'vue';

  import _ from 'lodash'

  export default Vue.extend({
      // props:['request'],

      data(){
          return {
              request: {},
              person: {},
              showPopup: false,
              isPreconfLayout:false,
          }
      },

      computed: {
          organization() {
              return this.$store.getters['organization/getOrganization']
          },
          profileImage() {
            if (this.request.hasOwnProperty('changed_fields')) {
                if (this.request.changed_fields.hasOwnProperty('profile_image_id')) {
                    return true
                }
            }
            return false;
          },
          // person() {
          //     return this.$store.getters['person/getPerson']
          // }

      },

      methods:{
          open(){
              this.showPopup=true;
          },
          async openEdit(request) {
              // Load Person
              await this.$store.dispatch("person/loadPerson", request.person_id )

              let person = this.$store.getters['person/getPerson']
              this.person = _.cloneDeep(person)
              this.person.date_of_birth = (this.person.date_of_birth).split("T")[0]
              
              // Load Image
              if (request.changed_fields.hasOwnProperty('profile_image_id')) {
                this.personImg64 = await this.$store.dispatch('core/getImage64', person.profile_image_id)
                this.requestImg64 = await this.$store.dispatch('core/getImage64', request.changed_fields.profile_image_id)
              }

              // Load Organization
              await this.$store.dispatch("organization/loadOrganization", this.person.organisation_id )
              const organization = this.$store.getters["organization/getOrganization"];

              //Load Layout
              await this.$store.dispatch('layout/loadLayout', organization.id_card_layout_id);
              const temp=this.$store.getters['layout/getLayout'];
              if (temp.preconfigured_layout_id === 1) {
                this.isPreconfLayout=true;
              } else {
                this.isPreconfLayout=false;
              }

              // Load Request
              if (request.changed_fields.hasOwnProperty('date_of_birth')) {
                request.changed_fields.date_of_birth = (request.changed_fields.date_of_birth).split("T")[0]
              }
              this.request = request

              this.showPopup=true;
          },

          getChangedField(fieldName) {
              if (this.request.hasOwnProperty('changed_fields')) {
                  if (this.request.changed_fields.hasOwnProperty(fieldName)) {
                      return this.request.changed_fields[fieldName]
                  }
              }
              return
          },
          async reviewAmendment(value) {
              await this.$store.dispatch('changeRequest/reviewAmendment', { id: this.request.id, value: value})
              this.showPopup=false;
          }

      }

  });
</script>
<style></style>
