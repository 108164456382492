<template>
  <div class="amendments-container">
    <h1 style="margin-left: 20px;">{{ $t("Änderungsanträge (offen)") }}</h1>    
    <div class="idcard-con">
      <div class="con mt-4">
        <vs-input class="search-input org-width" icon-after="search" :placeholder="$t('dashboard.expire-card.table.name-search')" v-model="search" icon="search" icon-no-border label-placeholder="icon-no-border" />
        <div class="outer-con2">
          <div class="table-container" style="overflow-y: auto; max-width: 100%;">
            <table>
              <thead>
                <tr>
                  <th @click="sort('updated_at')">{{$t("dashboard.amendments.table.request-from")}}<div class="arrow" v-if="'updated_at' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sort('organisation_name')">{{$t("dashboard.amendments.table.organization")}}<div class="arrow" v-if="'organisation_name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sort('person_last_name')">{{$t("Name")}}<div class="arrow" v-if="'person_last_name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sort('reviewed')">Status<div class="arrow" v-if="'reviewed' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                </tr>
              </thead>
              <tbody>

                <tr v-for="changeRequest in changeRequests" :key="changeRequest.id" @click="editChangeRequest(changeRequest)"> 
                  <td>{{ changeRequest.updated_at | moment("DD.MM.YYYY") }}</td>
                  <td>{{ changeRequest.organisation_name }}</td>
                  <td>{{ changeRequest.person_last_name }}, {{ changeRequest.person_first_name }}</td>

                  <td>
                    <div class="centerx">
                      <vs-chip v-if="changeRequest.reviewed===null" color="primary">Offen</vs-chip>
                      <vs-chip v-if="changeRequest.reviewed===false" color="danger">Abglehnt</vs-chip>
                      <vs-chip v-if="changeRequest.reviewed===true" color="success">genehmigt</vs-chip>
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <PopupAmendments ref="popup" />

  </div>
</template>

<script>
import Vue from "vue";
import PopupAmendments from "../../components/dashboard/PopupAmendments.vue";
import moment from 'moment'

export default Vue.extend({
  name: "Amendments",
  components: {
    PopupAmendments,
  },

  async mounted() {
    await this.$store.dispatch("changeRequest/loadChangeRequests");
  },


  computed: {
    changeRequests:function() {
      let requests = this.$store.getters["changeRequest/getChangeRequests"]
      requests.sort((a,b)=> {
          let modifier=1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
      });

      if (this.search) {
        return requests.filter( req => {
          const name = req.person_first_name + ' ' + req.person_last_name
          if ((name.toLowerCase()).includes(this.search.toLowerCase())) {
            return true
          }
          return false
        })
      } else {
        return requests;
      }
    },
  },


  data() {
    return {
      search: "",
      ascending: false,
      currentSort:'updated_at',
      currentSortDir:'asc'
    };
  },


  methods: {
    sort:function(s) {
        if(s === this.currentSort) {
          this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          this.ascending = !this.ascending;
        }
        this.currentSort = s;
    },
    popUp(user) {
      // this.prop_user = user;
      const popup = this.$refs.popup ;
      popup.open();
    },

    editChangeRequest(request) {
      const popup = this.$refs.popup ;
      popup.openEdit(request);
    },


    // formatDateTimeString(dateTimeString) {
    //   if (dateTimeString===null) {
    //     return
    //   }
    //   const dt = Date.parse(dateTimeString)
    //   const date = new Date(dt)

    //   var d = date.getDate()
    //   var m = date.getMonth()+1
    //   var y = date.getFullYear()
    //   var H = date.getHours()
    //   var M = date.getMinutes()
    //   return d + '.' + m + '.' + y + ' ' + H + ':' + M
    // }
  },

});
</script>
<style>
</style>
